@tailwind base;
@tailwind components;
@tailwind utilities;

.translate-z-0 {
  transform: translateZ(0px);
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #f13024;
}

.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #f13024;
}

@layer base {
  body {
    @apply bg-secondary overflow-hidden leading-relaxed;
  }
  .page {
    @apply w-full h-screen overflow-hidden;
  }
  .h1 {
    @apply text-[35px] leading-tight md:text-[60px] md:leading-[1.3] mb-8 font-semibold;
  }
  .h2 {
    @apply text-[35px] leading-tight md:text-[54px] md:leading-[1.3] mb-4 font-semibold;
  }
  p {
    @apply leading-[1.8] text-white/60 font-light;
  }
  .btn,
  .input {
    @apply h-[52px];
  }
  .input {
    @apply w-full h-[52px] rounded-lg pl-6 capitalize;
  }
  .input,
  .textarea {
    @apply bg-transparent outline-none focus:ring-1 focus:ring-accent border border-white/20 placeholder:text-white/30 placeholder:font-light;
  }
  .textarea {
    @apply w-full h-[180px] p-6 capitalize rounded-lg resize-none;
  }
}

/*Custom Button */
.box {
  --border-size: 3px;
  --border-angle: 0turn;
  width: 200px;
  height: 60px;
  font-weight: 500;
  font-size: 1.1rem;
  background-image: conic-gradient(from var(--border-angle), #ed6b1a, #ed6b1a),
    conic-gradient(
      from var(--border-angle),
      white 20%,
      transparent,
      #0d1726 30%
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 6px;
  animation: bg-spin 3s linear infinite;
  opacity: 0.9;
  transition: all 300ms ease;
  color: #0d1726;
}
.box:hover {
  color: #ed6b1a;
  background-image: conic-gradient(from var(--border-angle), #0d1726, #0d1726),
    conic-gradient(
      from var(--border-angle),
      white 20%,
      transparent,
      #05090e 30%
    );
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
